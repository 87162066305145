<template>
  <div class="flex flex-col space-y-2">
    <div class="bg-gray-200 rounded-5 py-2 text-xs text-tertiary flex items-stretch min-h-12">
      <div class="flex-grow px-4 flex flex-row items-center">
        <span>Software</span>
      </div>
      <div class="flex-shrink-0 px-4 flex flex-row items-center justify-end w-144">
        <div class="flex flex-row items-center">
          <span>Experience, years</span>
        </div>
      </div>
      <div v-if="edit" class="w-6 mr-4" />
    </div>
    <div
      v-for="(v, index) in $v.payload.$each.$iter"
      :key="index"
      class="bg-gray-100 rounded-5 py-2 text-xs text-tertiary flex items-stretch min-h-12"
    >
      <div class="flex-grow px-4 flex flex-row items-center">
        <template v-if="edit">
          <CSelect class="w-full" placeholder="Software *" :options="[]">
            <template #dropdown-menu="{ context, api }">
              <div class="bg-white flex flex-col py-2">
                <div
                  v-for="option in context.filteredOptions"
                  :key="api.getOptionKey(option)"
                  class="flex items-center cursor-pointer py-1 px-3 hover:bg-blue-200 group"
                  @click.prevent.stop="api.selectable(option) ? api.select(option) : null"
                >
                  <span
                    :class="{
                      'text-secondary44': !api.isOptionSelected(option),
                      'text-primary': !api.isOptionSelected(option),
                    }"
                    class="text-sm group-hover:text-white"
                  >
                    {{ option.label }}
                  </span>
                </div>
              </div>
            </template>
          </CSelect>
        </template>
        <template v-else>
          <div class="flex flex-row items-center overflow-hidden">
            <span>{{ v.$model.software }}</span>
          </div>
        </template>
      </div>
      <div class="flex-shrink-0 px-4 flex flex-row items-center w-144">
        <div class="flex-grow flex flex-row items-center">
          <template v-if="edit">
            <CInput
              v-model="v.$model.experience"
              :error-show="v.experience.$dirty && v.experience.$invalid"
              class="w-full"
              placeholder="Years *"
            />
          </template>
          <template v-else>
            <span>{{ v.$model.experience }}</span>
          </template>
        </div>
      </div>
      <div v-if="edit" class="flex-shrink-0 flex items-center w-6 mr-4">
        <div class="w-6 h-6 cursor-pointer" @click="onDeleteJobPreferenceClick(index)">
          <IconClose class="w-full h-full text-red-100" />
        </div>
      </div>
    </div>
    <div v-if="edit" class="flex flex-row items-center justify-end">
      <span class="cursor-pointer text-sm text-blue-200 hover:underline" @click="onAddJobPreferenceClick">
        + add software
      </span>
    </div>
  </div>
</template>

<script>
import CInput from 'devotedcg-ui-components/CInput.vue';
import CSelect from 'devotedcg-ui-components/CSelect.vue';
import { IconClose } from 'devotedcg-ui-components/icons';
import { cloneDeep } from 'lodash';

export default {
  name: 'VendorJobPreferences',
  components: {
    CInput,
    CSelect,
    IconClose,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      payload: [],
    };
  },
  watch: {
    edit: {
      handler(value) {
        if (value) {
          if (this.value.length) {
            this.payload = cloneDeep(this.value);
          } else {
            this.payload = [{ software: '', experience: '' }];
          }
        } else {
          this.payload = cloneDeep(this.value);
        }
      },
      immediate: true,
    },
  },
  validations: {
    payload: {
      $each: {
        software: {},
        experience: {},
      },
    },
  },
  methods: {
    onDeleteJobPreferenceClick(index) {
      if (this.payload) {
        this.payload.splice(index, 1);
      }
    },
    onAddJobPreferenceClick() {
      if (this.payload) {
        this.payload.push({ software: '', experience: '' });
      }
    },
  },
};
</script>
